import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ModuleSection = ({ children, sectionClass, sectionSpacing, removeContainer, id, ...rest }) => {
    if (removeContainer) {
        return(
            // <InView {...inViewOptions}>
            //     {({ ref, inView }) => (
                    <>
                        {children &&
                            <section
                                id={id}
                                className={`section ${sectionSpacing ? sectionSpacing : "py-48 py-md-64 py-lg-120"}${sectionClass ? " " + sectionClass : ""}`}
                                //ref={ref}
                                // initial="hidden"
                                // animate={inView ? "visible" : "hidden"}
                                //variants={containerVariants}
                                {...rest}
                            >
                                {children}
                            </section>
                        }
                    </>
            //     )}
            // </InView>
        )
    } else {
        return(
            // <InView {...inViewOptions}>
            //     {({ ref, inView }) => (
                    <>
                        {children &&
                            <section
                                id={id}
                                className={`section ${sectionSpacing ? sectionSpacing : "py-48 py-md-64 py-lg-120"}${sectionClass ? " " + sectionClass : ""}`}
                                //ref={ref}
                                // initial="hidden"
                                // animate={inView ? "visible" : "hidden"}
                                //variants={containerVariants}
                                {...rest}
                            >
                                <Container fluid="lg">
                                    <Row>
                                        <Col xs={12}>{children}</Col>
                                    </Row>
                                </Container>
                            </section>
                        }
                    </>
            //     )}
            // </InView>
        )
    }
}

export default ModuleSection