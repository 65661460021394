/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 import { useLocation } from "@reach/router"
 
 const Seo = ({ description, lang, meta, image: metaImage, title }) => {
   var { pathname } = useLocation()
     const { site } = useStaticQuery(
         graphql`
             query {
                 site {
                     siteMetadata {
                         siteUrl
                         description
                         author
                         title
                     }
                 }
             }
         `
     )
   const metaDescription = description?.replace(" | Exclusive Links", "") || site.siteMetadata.description?.replace(" | Exclusive Links", "")
   // console.log(metaImage)
   var imageDef = metaImage && metaImage?.src
     ? `${site.siteMetadata.siteUrl}${metaImage.src}`
     : metaImage ? metaImage
     : null

    // if(imageDef && imageDef.includes("ggfx")){
    //   var imageDef = imageDef?.replace("/i.","/x.");
    //   var parts = imageDef?.split('/');
    //   var imageDef = imageDef?.replace(parts[parts.length-1],"336x220/"+parts[parts.length-1]);
    // }

    var currentUrl = ""
    currentUrl = process.env.GATSBY_SITE_URL + pathname

    // set no index for result pagination
   let hasPagination = false
   if (pathname !== undefined && pathname) {
     let pageStr = pathname.split("page-")
     hasPagination = pageStr[1] || false
     if (pageStr[0] !== undefined) {
       currentUrl = process.env.GATSBY_SITE_URL + pageStr[0]
     }
   }
 
   if (!currentUrl.endsWith("/")) currentUrl = currentUrl + "/"
 
   //remove multiple slashes
   currentUrl = currentUrl.replace(/([^:]\/)\/+/g, "$1")
   //set lowercase url
   currentUrl = currentUrl.toLowerCase()
 
   const logoimage = process.env.GATSBY_SITE_URL+"images/Blue_Trans.jpg"
  
   var hrefLanguage = ""
   
    if(pathname === "/ru/"){
      hrefLanguage = "ru-ru"
    }else if(pathname === "/fr/"){
      hrefLanguage = "fr-fr"
    }else if(pathname === "/uae/"){
      hrefLanguage = "ar-ae"
    }else if(pathname === "/pl/"){
      hrefLanguage = "pl-pl"
    }else if(pathname === "/cn/"){
      hrefLanguage = "cn-cn"
    }else if(pathname === "/"){
      hrefLanguage = "en"
    }else{
      hrefLanguage = ""
    }
  
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       link={[
         {
           href: currentUrl,
           rel: "canonical",
         },
         {
          rel: "alternate",
          href: currentUrl,
          hrefLang: hrefLanguage,
         }
       ]}
       
       title={title}
       titleTemplate={`%s | ${site.siteMetadata.title}`}
       meta={[
         {
             name: `googlebot`,
             content: `all`,
         },
         {
             name: `robots`,
             content: `all`,
         },
         {
             name: `description`,
             content: `${metaDescription?.replace(" | Exclusive Links", "")}`,
         },        
         {
             property: `og:title`,
             content: `${title} | ${site.siteMetadata.title}`,
         },
         {
             property: `og:description`,
             content: `${metaDescription?.replace(" | Exclusive Links", "")}`,
         },
         {
             property: `og:type`,
             content: `website`,
         },
         {
           property: `og:image:type`,
           content: `image/jpeg`,
         },
         {
           property: `og:url`,
           content: `${currentUrl}`,
         },
         {
             name: `twitter:creator`,
             content: site.siteMetadata.author || ``,
         },
         {
           property: "og:image:width",
           content: "400",
         },
         {
           property: "og:image:height",
           content: "300",
         },
         {
             name: `twitter:title`,
             content: `${title} | ${site.siteMetadata.title}`,
         },
         {
           name: "twitter:card",
           content: "summary_large_image",
         },
         {
           property: `twitter:url`,
           content: `${currentUrl}`,
         },
         {
             name: `twitter:description`,
             content: `${metaDescription?.replace(" | Exclusive Links", "")}`,
         },
         {
             name: `twitter:site`,
             content: site.siteMetadata.author,
         },
         {
           name: `facebook-domain-verification`,
           content: ``,
         },		
         // {
         // 	name: `google-site-verification`,
         // 	content: `H3x6Su1zsI0ak8UO5QenSARRHgq3a8Wdsg5G-kn-zLg`,
         // },
       ]
       .concat(
        metaImage
           ? [
               {
                 property: "og:image",
                 content: imageDef,
               },
               {
                 property: `twitter:image`,
                 content: imageDef,
               },
               {
                 property: "og:image:secure_url",
                 content: imageDef,
               },
               {
                 itemprop: `image`,
                 content: imageDef,
               }
             ]
            : [
              {
                property: "og:image",
                content: logoimage,
              },
              {
                property: "og:image:secure_url",
                content: logoimage,
              },
              {
                property: "og:image:width",
                content: "500",
              },
              {
                property: "og:image:height",
                content: "400",
              },
              {
                name: "twitter:image",
                content: logoimage,
              },
              {
                itemprop: `image`,
                content: logoimage,
              }
            ]
       )
       .concat(meta)}
     />
   )
 }
 
 Seo.defaultProps = {
     lang: `en`,
     meta: [],
     description: ``,
 }
 
 Seo.propTypes = {
     description: PropTypes.string,
     lang: PropTypes.string,
     meta: PropTypes.arrayOf(PropTypes.object),
     title: PropTypes.string.isRequired,
     image: PropTypes.shape({
         src: PropTypes.string.isRequired,
         height: PropTypes.number,
         width: PropTypes.number,
     }),
 }
 
 export default Seo
